@import "../assets/fonts/Flama/index.css";
@import "../assets/fonts/Capitolium/index.css";
@import "../assets/fonts/AtlasGrotesk/index.css";
@import "../assets/fonts/Montserrat/index.css";
@import "../assets/fonts/PublicoHeadline/index.css";

@import "../assets/themes/watch.css";
@import "../assets/themes/kforum.css";
@import "../assets/themes/monitor.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .prose :where(iframe):not(:where([class~="not-prose"] *)) {
    @apply w-full max-w-full lg:max-w-full;
  }

  .prose .consent-dependent-place-holder {
    @apply my-2 !flow-root border border-silver px-3;
  }

  .prose .consent-dependent-place-holder h2 {
    @apply mt-3;
  }

  body:before {
    content: "xs";
    display: none;
    visibility: hidden;
  }

  @screen sm {
    body:before {
      content: "sm";
    }
  }

  @screen md {
    body:before {
      content: "md";
    }
  }

  @screen lg {
    body:before {
      content: "lg";
    }
  }

  @screen xl {
    body:before {
      content: "xl";
    }
  }

  @screen 2xl {
    body:before {
      content: "2xl";
    }

    .banner-container {
      /* Some browsers shows the scrollbar (ex Edge & Chrome) so we have to consider this in our calculation */
      --approximate-scrollbar-width: 20px;

      --sticky-banner-max-width: calc(
        50vw -
        (var(--approximate-scrollbar-width) / 2) -
        theme("container.screens.xl") /
        2
      );

      display: flex;
      left: calc(50% + (var(--sticky-banner-max-width) / 2));
      max-width: calc(
        theme("container.screens.xl") +
        var(--sticky-banner-max-width)
      );
      position: relative;
      transform: translateX(-50%);
    }

    .sticky-banner {
      max-width: var(--sticky-banner-max-width);
    }
  }
}

@layer components {
  .footer-link {
    @apply inline-block w-full text-pretty break-words px-2 py-1 text-center underline transition-colors visited:text-silver hover:no-underline sm:px-0 sm:py-0.5 sm:text-left;
  }

  .base-button {
    @apply inline-flex min-h-6 select-none items-center justify-center rounded-sm px-3 py-1 text-sm font-theme-bold uppercase disabled:border-silver disabled:bg-silver disabled:text-white lg:min-h-5;
  }

  .base-hover-button {
    @apply transition duration-200 active:enabled:scale-99 hover:disabled:bg-silver motion-reduce:transition-none;
  }
  .toolbar-menu-item {
    @apply flex h-full w-full flex-col items-center justify-center;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
